import { getFirebaseServices } from '../utils/firebase-config'
import { Database, get, onChildAdded, onChildChanged, onChildRemoved, ref, update } from 'firebase/database'
import { OrdersService } from './orders.service'
import { OrderItemsDto, OrdersDto } from '../dtos/orders.dto'
import { EventEmitter } from 'events'
import { FirebaseEvents } from './firebase-events'
import { ItemsCount, ItemsCountService } from './items-count.service'

export class FirebaseService extends EventEmitter {
  // singelton instance
  private static _instance: FirebaseService
  private database: Database | undefined
  private _listenersAdded = false

  public static getInstance(): FirebaseService {
    return this._instance || (this._instance = new this())
  }

  public async initializeFirebase() {
    const { database } = await getFirebaseServices()
    this.database = database
  }

  public async readOrdersData() {
    if (!this.database) {
      // TODO log error
      return
    }
    const ordersRef = ref(this.database, '/orders')
    const snapshot = await get(ordersRef)
    const data = snapshot.val()
    if (!data) {
      // TODO log error
      return
    }
    const orders: OrdersDto[] = []
    const itemsCountMap = new Map<string, ItemsCount>()
    Object.keys(data).forEach((key, i) => {
      const order = new OrdersDto(data[key])
      let toBeInserted = false
      order.items.forEach((item, title) => {
        if (!item.status) {
          item.itemId = item.itemId ?? item.name
          if (item.itemId.toLowerCase() === 'custom name') {
            item.itemId = `${item.itemId} -- ${item.ids[0]}`
          }
          if (!itemsCountMap.has(item.itemId)) {
            itemsCountMap.set(item.itemId, {
              title: item.name,
              count: item.quantity,
              time: order.createdTime,
            })
          } else {
            itemsCountMap.set(item.itemId, {
              title: item.name,
              count: (itemsCountMap.get(item.itemId)?.count ?? 0) + item.quantity,
              time: order.createdTime,
            })
          }
          toBeInserted = true
        }
      })
      if (toBeInserted) {
        orders.push(order)
      }
    })
    orders.sort((a, b) => b.createdTime - a.createdTime)
    OrdersService.getInstance().setOrders(orders)
    ItemsCountService.getInstance().setItemsCountMap(itemsCountMap)
    this.emit(FirebaseEvents.ORDERS_UPDATED)
  }

  public addListeners() {
    if (!this.database) {
      // TODO log error
      return
    }

    if (this._listenersAdded) {
      console.warn('Listeners already added')
      return
    }
    this._listenersAdded = true // Set the flag to true after adding listeners

    const ordersRef = ref(this.database, '/orders')
    onChildChanged(ordersRef, (snapshot) => {
      const snapshotData = snapshot.val()
      console.log('Order changed', snapshotData)
      const order = new OrdersDto(snapshotData)
      let toBeInserted = false
      let toBeRemoved = true
      const orders = OrdersService.getInstance().getOrders()
      const orderIndex = orders.findIndex((o) => o.id === order.id)
      const updatedOrders = [...orders]
      if (orderIndex !== -1) {
        updatedOrders[orderIndex] = order
        // checking state for open orders
        if (order.state === 'open') {
          order.items.forEach((item, title) => {
            if (!item.status) {
              toBeRemoved = false
            }
          })
          if (toBeRemoved) {
            updatedOrders.splice(orderIndex, 1)
          }
        }
      } else {
        order.items.forEach((item, title) => {
          if (!item.status) {
            toBeInserted = true
          }
        })
        if (toBeInserted) {
          updatedOrders.push(order)
        }
      }
      OrdersService.getInstance().setOrders(updatedOrders)
      ItemsCountService.getInstance().refreshItemCountMap()
      this.emit(FirebaseEvents.ORDERS_UPDATED)
    })

    onChildAdded(ordersRef, (snapshot) => {
      const snapshotData = snapshot.val()
      // console.log('Order added', snapshotData)
      const order = new OrdersDto(snapshotData)
      let toBeInserted = false
      order.items.forEach((item, title) => {
        if (!item.status) {
          toBeInserted = true
        }
      })
      const orders = [...OrdersService.getInstance().getOrders()]
      const orderIndex = orders.findIndex((o) => o.id === order.id)
      if (orderIndex !== -1) {
        orders[orderIndex] = order
      } else {
        if (toBeInserted) {
          orders.push(order)
        }
      }
      OrdersService.getInstance().setOrders(orders)
      ItemsCountService.getInstance().refreshItemCountMap()
      this.emit(FirebaseEvents.ORDERS_UPDATED)
    })

    onChildRemoved(ordersRef, (snapshot) => {
      const snapshotData = snapshot.val()
      const order = new OrdersDto(snapshotData)
      console.log('Order removed', order)
      const orders = OrdersService.getInstance().getOrders()
      const orderIndex = orders.findIndex((o) => o.id === order.id)
      if (orderIndex !== -1) {
        orders.splice(orderIndex, 1)
        OrdersService.getInstance().setOrders(orders)
        ItemsCountService.getInstance().refreshItemCountMap()
        this.emit(FirebaseEvents.ORDERS_UPDATED)
      }
    })
  }

  public async updateOrderItemStatus(
    orderId: string,
    title: string,
    status: boolean,
    item: OrderItemsDto,
  ) {
    if (!this.database) {
      // TODO log error
      return
    }
    const orderRef = ref(this.database, `/orders/${orderId}/items/${title}/`)
    await update(orderRef, { ...item, status: status })
  }

  reset() {
    this._listenersAdded = false
  }
}
